import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { AnalyticsChartComponent } from './analytics-chart.component';

@NgModule({
  declarations: [AnalyticsChartComponent],
  exports: [AnalyticsChartComponent],
  imports: [
    CommonModule,
    TranslateModule,
    HighchartsChartModule,
    MatProgressSpinnerModule,
  ],
})
export class AnalyticsChartModule {}
