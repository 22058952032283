import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { DerTableComponent } from './der-table.component';

@NgModule({
  declarations: [DerTableComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatSortModule,
    MatRippleModule,
    MatPaginatorModule,
  ],
  exports: [DerTableComponent],
})
export class DerTableModule {}
